import React, { useEffect, useState } from "react";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Container,
  Tabs,
  Tab,
  TableRow,
  TableCell,
  TableContainer,
  TabPanel,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import NewHeader from "../../../src/layouts/dashboard/header/NewHeader";
import ArrowRight from "../../assets/breadcum-arrow.svg";
import ProfileCyber from "../../assets/cyber-security.png";
import IconAlert from "../../assets/icon-alert.svg";
import OtherInformation from "../../assets/other-information.svg";
import ProfileImage from "../../assets/profile-information-image.png";
import RelationshipImg from "../../assets/relationship-info.png";
import DocumentIcon from "../../assets/document-icon.png";
import QuestionnaireSubmitted from "../QuestionnaireSubmitted";
import { useLocation, useParams } from "react-router-dom";
import ViewAnswerPage from "../ViewAnswerPage";
import ComplianceScoring from "./ComplianceScoringPage"
import ComplianceMappingPage from "./ComplianceMappingPage";
import PlaneSend from "../../assets/plane-send.svg";
import Inprogress from "../../assets/in-progress.svg";
import Subimetted from "../../assets/submitted.svg";
import ExtendedDeadline from "../../assets/ExtendedDeadline.svg";
import Completed from "../../assets/completed-icon.svg";
import Breadcrumb from "./Breadcrumb";


function VendorDetailAnswer() {
  const paramData = useParams();
  const location = useLocation();
  const [assessmentData, setAssessmentData] = useState(paramData?.id);
  const { data } = location.state;
  const { email: receiverEmail, prevUrl: prevUrl } = location.state;
  const [parentValue, setParentValue] = useState(0); // State for parent tabs
  const [childValue, setChildValue] = useState(0); // State for child (inner) tabs
  const [assessmentStatus, setAssessmentStatus] = useState(data.assessmentStatus);
  const [domain, setDomain] = useState(data.url);
  const [logoLink, setLogoLink] = useState();
  const handleParentChange = (event, newValue) => {
    setParentValue(newValue);
    setChildValue(0); // Reset child tabs when switching parent tabs
  };
  console.log("tytyrt", data)
  const handleChildChange = (event, newValue) => {
    setChildValue(newValue);
  };
  const statusOrder = ['Sent', 'In Progress', 'Submitted', 'Engagement', 'Extended Deadline', 'Completed'];
  const breadcrumbs = [
    { label: 'Ecosystem', path: '/dashboard/vendors' },
    { label: 'Security', path: `/dashboard/vendorDetails/${domain}` },
    { label: 'Compliance' },
  ];

  console.log("logoLink", logoLink);
  useEffect(() => {

    if (data?.setId) {
      getQuestionairelogoLink(data.setId);
    }

  }, [data]);

  const getQuestionairelogoLink = async (setId) => {
    try {

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_LOGO_LINK_BY_SET_ID}?id=${setId}`,
          {}
        )
          .then((data) => {
            console.log(data);
            setLogoLink(data.data.data.logo)
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" });

          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      // setshowLoader(false);
      // setError(true);
      return false;
    }
  };


  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Security Profile" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
                {/* <h3>Vendor Info</h3> */}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div className="cybersecurity-assessment-box">
              <p>
                <span className="dpcyber">
                  {logoLink ?
                    <img className="logo-image-vendor attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${logoLink}`} alt='logo' />
                    :
                    <img src={ProfileCyber} alt="icon" />
                  }
                </span>
                {data?.set || "-"}
              </p>
              <span> Deadline: {data?.deadline || "N/A"}</span>
            </div>

            <div className="three-steps-tabs viewdetail-answer">
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 0 ? 'activeprofile' : ''}`}>
                <div className="steps-image">
                  <img src={PlaneSend} alt="icon" />
                </div>
                <p>Sent </p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 1 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={Inprogress} alt="icon" />
                </div>
                <p>In Progress</p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 2 ? 'activeprofile' : ''}`}>
                <div className="steps-image">
                  <img src={Subimetted} alt="icon" />
                </div>
                <p>Submitted</p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 3 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={RelationshipImg} alt="icon" />
                </div>
                <p>Engagement</p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 4 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={ExtendedDeadline} alt="icon" />
                </div>
                <p>Extended Deadline</p>
              </div>
              <div className={`select-steps ${statusOrder.indexOf(assessmentStatus) >= 5 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={Completed} alt="icon" />
                </div>
                <p>Completed</p>
              </div>
            </div>

            <Box className="tophangechange" sx={{ width: '100%' }}>
              {/* Parent Tabs */}
              <Tabs
                value={parentValue}
                onChange={handleParentChange}
                aria-label="Parent Tabs" className="tab-main-heading"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab label="Answer" />
                <Tab label="Scoring" />
                <Tab label="Mapping" />
              </Tabs>

              <Box sx={{ p: 0 }}>
                {/* Content for Parent Tab One with nested child tabs */}
                {parentValue === 0 && (
                  <div className="space-questionnaire">
                    {/* <div className="tabs-bg top-demo-space w-100-160"> */}
                    <ViewAnswerPage parentValue={parentValue} />
                    {/* {navigate(`/dashboard/view-answer-page/${id}`, { state: { email, prevUrl: '/dashboard/assessment' } })} */}
                    {/* <Tabs
                        value={childValue}
                        onChange={handleChildChange}
                        aria-label="Child Tabs"
                        orientation="vertical"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Child Tab One" />
                        <Tab label="Child Tab Two" />
                        <Tab label="Child Tab Three" />
                    </Tabs> */}

                    {/* </div> */}
                    {/* <div className="tabs-bg top-demo-space w-100-cover">
                    <Box sx={{ p: 0 }}>
                        {childValue === 0 && (
                        <div className="question-part">
                            <div className="mapping-issue">
                            <img src={IconAlert} alt="icon" /> Issue Mapped
                            </div>
                            <div className="flex-preview-tab que-indexing">
                            <div style={{ display: 'flex' }}>
                                <p className="preview-question-heading">Q.1</p>
                                <span className="data-text-preview">
                                Develop a plan to continuously assess and track
                                vulnerabilities on all enterprise assets within the
                                enterprise’s infrastructure, in order to remediate,
                                and minimize, the window of opportunity for attackers.
                                Monitor public and private industry sources for new
                                threat and vulnerability information.
                                </span>
                            </div>
                            </div>
                            <div className="options-flex">
                            <FormControl>
                                <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                >
                                <FormControlLabel
                                    value="#"
                                    control={<Radio />}
                                    label="Yes"
                                    disabled
                                />
                                <FormControlLabel
                                    value="#"
                                    control={<Radio />}
                                    label="No"
                                    disabled
                                />
                                <FormControlLabel
                                    value="#"
                                    control={<Radio />}
                                    label="N/A"
                                    disabled
                                />
                                </RadioGroup>
                            </FormControl>
                            </div>
                            <div className="flex-preview-tab">
                            <TextareaAutosize
                                aria-label="Write here"
                                minRows={3}
                                className="textarea-auto-size"
                                disabled
                            />
                            </div>
                        </div>
                        )}
                        {childValue === 1 && <Typography>Content for Child Tab Two</Typography>}
                        {childValue === 2 && <Typography>Content for Child Tab Three</Typography>}
                    </Box>
                    </div> */}
                  </div>
                )}

                {/* Content for Parent Tab Two */}
                {parentValue === 1 && <Typography><ComplianceScoring /> </Typography>}

                {/* Content for Parent Tab Three */}
                {parentValue === 2 && <Typography><ComplianceMappingPage domainName={domain} assessmentId={assessmentData} /></Typography>}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default VendorDetailAnswer;
