import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ crumbs }) => {
  return (
    <nav aria-label="breadcrumb">
      <ul style={{ listStyle: 'none', display: 'flex', padding:'0px' }}>
        {crumbs.map((crumb, index) => (
          <li key={index} style={{ marginRight: '5px' }}>
            {crumb.path ? (
              <Link style={{color:"white"}} to={crumb.path}>{crumb.label}</Link>
            ) : (
              <span>{crumb.label}</span>
            )}
            {index < crumbs.length - 1 && (
              <span style={{ marginLeft: '5px', marginRight: '5px' }}> &gt; </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;