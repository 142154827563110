import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import Tabs from '@mui/material/Tabs';
import FilterIco from '../assets/filter-icon.svg';
import Tab from '@mui/material/Tab';
import IconAlert from '../assets/icon-alert.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TableContainer, Table, Button, FormControl, FormControlLabel, TableHead, TableBody, TableRow, TableCell, Paper, Box, Checkbox } from '@mui/material';
import DnsImage from '../assets/dns-image.svg';
import LinkIco from '../assets/link-ico.svg';
import HighButton from '../assets/high-button.svg';
import Critical from '../assets/critical-img.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import GetRequest from "../components/apiConnections/getRequest";
import redirectIcon from "../assets/redirect-icon.svg";

function MappedIssue({ data, issueMapped }) {
  // console.log("data", data)
  // console.log("issue", issueMapped)
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(0); // State to track the active tab
  const drawerRef = useRef(null);
  const param = useParams();
  const [vendorUrl, setVendorUrl] = useState(param.url);
  const [showLoader, setShowLoader] = useState(false);
  const [issues, setIssues] = useState([])
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue); // Set the new active tab
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setOpen(false); // Close the drawer
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup event listener
    };
  }, [open]);

  const extractInfoFromRemark = (remark) => {
    if (!remark) {
      return {
        remark_text: null,
        document_name: null,
        page_number: null,
        reference: null
      };
    }

    const remarkTextMatch = remark.match(/(.*?)(?=Document Name:)/s);
    const docNameMatch = remark.match(/Document Name:\s*(.+)\n/);
    const page_numberMatch = remark.match(/Page number:\s*(\d+)\n/);
    const referenceMatch = remark.match(/Reference:\s*(.+)/);

    return {
      remark_text: remarkTextMatch ? remarkTextMatch[0].trim() : null,
      document_name: docNameMatch ? docNameMatch[1].trim() : null,
      page_number: page_numberMatch ? page_numberMatch[1].trim() : null,
      reference: referenceMatch ? referenceMatch[1].trim() : null
    };
  };

  const { remark_text, document_name, page_number, reference } = extractInfoFromRemark(data?.remark);

  const DrawerList = (
    <Box className="drawerlist" sx={{ width: 800 }} ref={drawerRef} role="presentation">
      <Box sx={{ width: '100%' }}>
        {/* Tabs */}
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} className="mapping-tab-heading" aria-label="basic tabs example">
            <Tab className="active-color-remove" label="Mapped Issue" />
            <Tab className="active-color-remove" label="Evidence" />
          </Tabs>
        </Box>

        {/* Tab content */}
        <Box className="bordertab-none">
          {/* Content for the first tab*/}
          {value === 0 && (
            <>
              {data?.issueName && 
                <div className="mapping-issue">
                  <img src={IconAlert} alt="icon"/> Issue Mapped
                </div>
              }
              {/* <div className="issue-mapped-tab">
                <p>A mapped issue indicates that a question is invalidated due to a detected vulnerability, proactively identifying conflicts with expected controls. This serves as an early insight into existing issues.</p>
              </div> */}
              <div className="question-mapping" style={{ display: 'flex' }}>
                <p className="preview-question-heading">Q.1</p>
                <span className="data-text-preview">
                  {data?.text}
                </span>
              </div>

              <FormControl className="form-group-radiotype" >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={data?.answer}
                >
                  {data?.options?.map((option, j) => {
                    return (
                      <FormControlLabel
                        value={option.title}
                        control={<Radio />}
                        label={option.title}
                        className="form-control-labels"
                        // onChange={(e) =>
                        //   readRadioButton(e, i)
                        // }
                        disabled={true}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
              <Box className="table-top-map" sx={{ overflowX: 'auto' }}> {/* To handle horizontal scroll on small screens */}
                <TableContainer>
                  <Table sx={{ minWidth: '100%' }} aria-label="responsive table">
                    <TableHead className="table-header-mapping">
                      <TableRow>
                        <TableCell>Issue</TableCell>
                        <TableCell align="left">Category</TableCell>
                        <TableCell align="left">Severity</TableCell>
                        <TableCell align="left">Issue</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {issueMapped && issueMapped.length > 0 ? (
                        issueMapped?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row?.Issue}</TableCell>
                            <TableCell>
                              <div className="dns-box">
                                <img src={DnsImage} alt="icon" />
                                {row?.category}
                              </div>
                            </TableCell>
                            <TableCell align="left" className="button-high-low">
                              {row?.Severity}
                            </TableCell>
                            <TableCell align="left">{row?.riskScore ?? "- "}</TableCell>
                            <TableCell align="left">
                              <a href={row?.Data}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div>
                                  <img src={redirectIcon} alt='icon' style={{ marginLeft: '8px', position: 'relative', top: '7px' }}></img>
                                </div>
                              </a>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center" style={{ textAlign: 'center', verticalAlign: 'middle', padding: '20px' }}>
                            No issues available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}

          {/* Content for the second tab*/}
          {value === 1 && (
            <>
              {data?.answer ?
                <>
                  {data?.issueName && 
                    <div className="mapping-issue">
                      <img src={IconAlert} alt="icon"/> Issue Mapped
                    </div>
                  }
                  <div className="question-mapping" style={{ display: 'flex' }}>
                    <p className="preview-question-heading">Q.1</p>
                    <span className="data-text-preview">
                      {data?.text}
                    </span>
                  </div>

                  {data?.optionType === "MCQ" ?
                    <FormControl>
                      {/* {console.log("Ented in MCQ ", data)} */}
                      {data?.options?.map((option, j) => {
                        return (
                          <FormControlLabel
                            value={j + 1}
                            control={
                              <Checkbox
                                checked={data.answer?.includes(
                                  `${j + 1}`
                                )}
                              />
                            }
                            label={option.title}
                            disabled
                          // onChange={(e) =>
                          //   questionnaireAnswerConfig(e, i)
                          // }
                          // disabled={submitted ? true : false}
                          />
                        );
                      })}
                    </FormControl>
                    :
                    <>
                      <FormControl className="form-group-radiotype" >
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue={data?.answer}
                        >
                          {data?.options?.map((option, j) => {
                            return (
                              <FormControlLabel
                                value={option.title}
                                control={<Radio />}
                                label={option.title}
                                className="form-control-labels"
                                // onChange={(e) =>
                                //   readRadioButton(e, i)
                                // }
                                disabled={true}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </>
                  }
                  {remark_text &&
                    <>
                      <div className="evidence-mapped">Evidence Mapped</div>
                      <div className="evidence-map-questions">
                        <div className="question-mapping" style={{ display: 'flex' }}>
                          <p className="preview-question-heading">E.1</p>
                          <span className="data-text-preview">
                            {remark_text || "No remark provided"}
                          </span>
                        </div>
                        <div className="flex-views-btn">
                          <div className="flexpdf-page">
                            <span>{data?.document_name}</span>

                            <span style={{ marginLeft: "23px" }}>{data?.page_number && (
                              <>
                                Page {data?.page_number}
                              </>
                            )}</span>
                          </div>

                          {data?.document_name &&
                            <Link
                              to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${data?.document_name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="contained" className="viewbutton-map">View </Button>
                            </Link>
                          }
                        </div>
                      </div>
                    </>
                  }
                </>
                :
                <>
                  <span className="data-text-preview">
                    No Evidence Available
                  </span>
                </>
              }
            </>
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Loader show={showLoader} />
      {DrawerList}
    </div>
  );
}

export default MappedIssue;
