import React, { useState } from "react";
import Chart from "react-apexcharts";

function ComplianceBarChart({fillPercentage}) {
  console.log('fillPercentage',fillPercentage)
  // const [fillPercentage, setFillPercentage] = useState(10);

  return (
    <>
      <div
        style={{
          backgroundColor: "#322671",
          borderRadius: "25px",
          padding: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "max-content",
          height: "10px",
          margin: "10px 0 0",
          position: "relative",
          top: "5px",
        }}
      >
        <span className="chart-scores-num">{fillPercentage}/100</span>
        <Chart
          options={{
            chart: {
              type: "bar",
              height: 50,
              borderRadius: 50,
              sparkline: {
                enabled: true, // Hide axes and gridlines
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
                borderRadius: 5,
                barHeight: "30%",
                distributed: true,
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                gradientToColors: ['#A11626'],
                stops: [0, 100],
              },
            },
            xaxis: {
              categories: [''],
              max: 100,
            },
            fill: {
              colors: ['#A11626'],
            },
            tooltip: {
              enabled: false,
            },
          }}
          series={[{ data: [fillPercentage] }]}
          type="bar"
          height={39}
        />
      </div>
    </>
  );
}

export default ComplianceBarChart;
