import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  CircularProgress,
  Box,
} from "@mui/material";
import CritiacalImg from "../../assets/critical-image.svg";
import ArrowRight from "../../assets/arrow-right-profilerisk.svg";
import ImpactImges from "../../assets/impact-imges.svg";
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useSnackbar } from "notistack";
import GetRequest from "../../components/apiConnections/getRequest";
import HightImage from '../../assets/high-img.svg';
import MediumImage from '../../assets/medium-img.svg';
import CriticalImage from '../../assets/critical-image.svg';
import LowImage from '../../assets/low-img.svg';

function RiskProfile({ domainName }) {
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inherentRisk, setInherentRisk] = useState(0);
  const [residualRisk, setResidualRisk] = useState(0);
  const [acceptableRisk, setAcceptableRisk] = useState('');
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [gdprTotalQuestions, setGdprTotalQuestions] = useState(0);
  const [iso27001ControlTotalQuestions, setIso27001ControlTotalQuestions] = useState(0);
  const [nistTotalQuestions, setNistTotalQuestions] = useState(0);
  const [gdprFailedQuestions, setGdprFailedQuestions] = useState(0);
  const [iso27001ControlFailedQuestions, setIso27001ControlFailedQuestions] = useState(0);
  const [nistFailedQuestions, setNistFailedQuestions] = useState(0);
  const [gdprPassedQuestions, setGdprPassedQuestions] = useState(0);
  const [iso27001ControlPassedQuestions, setIso27001ControlPassedQuestions] = useState(0);
  const [nistPassedQuestions, setNistPassedQuestions] = useState(0);
  const [totalBusinessRiskQuestions,setTotalBusinessRiskQuestions]=useState(0);
  const [operationalRisk, setOperationalRisk] = useState(0);
  const [complianceRisk, setComplianceRisk] = useState(0);
  const [reputationalRisk, setReputationalRisk] = useState(0);
  const [financialRisk, setFinancialRisk] = useState(0);
  const [cybersecurityRisk, setCybersecurityRisk] = useState(0);

  useEffect(() => {
    getBusinessRiskRatio();
    getCompplianceMapping();
    getResidualInheritanceAcceptableRisk();
  }, []);

  const options = {
    chart: {
      type: 'radar',
      toolbar: {
        show: false,
      },
    },
    title: {
      text: '',
      style: {
        fontFamily: 'Mona-Sans',
      },
    },
    xaxis: {
      categories: ['Compliance', 'Cybersecurity', 'Operational', 'Reputational', 'Financial'],
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Mona-Sans',
          color: '#fff',
        },
      },

    },
    yaxis: {
      max: totalBusinessRiskQuestions,
      tickAmount: 5.3,
      show: false,
    },
    stroke: {
      width: 2, // Width of the outline
      colors: ['#8252B5'],

    },
    plotOptions: {
      radar: {
        polygons: {
          // strokeColors: 'rgba(255, 255, 255, 0.2)',
          strokeWidth: 1,
        }
      }
    },

    fill: {
      gradient: {
        shade: 'dark',
        type: 'vertical', // You can change to 'horizontal', 'diagonal1', etc.
        shadeIntensity: 1,
        gradientToColors: ['#363FEB'], // End color of the gradient
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
      }
    },

  };
  const series = [
    {
      name: '',
      data: [complianceRisk, cybersecurityRisk, operationalRisk, reputationalRisk, financialRisk],
      fill: {
        colors: ['#4CAF50'], // Change this to your desired fill color
      },
    },
  ];

  // Define options for the concentric radial bars
  const options1 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent', // Set chart background to transparent
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Average',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Failed', 'Total', 'Passed'],
    colors: ['#FF4560', '#FEB019', '#00E396'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#FF4560', '#FEB019', '#00E396'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  // Series data for each concentric radial bar
  const gdprseries = [gdprFailedQuestions, gdprTotalQuestions, gdprPassedQuestions];
  const nistSeries = [nistFailedQuestions, nistTotalQuestions, nistPassedQuestions]
  const ISOseries = [iso27001ControlFailedQuestions, iso27001ControlTotalQuestions, iso27001ControlPassedQuestions]

  const getBusinessRiskRatio = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_BUSSINESS_RISK_RATIO}?domain=${domainName}`
      )
        .then((res) => {
          const businessRiskData = res.data.getBusinessRisk;
          setTotalBusinessRiskQuestions(businessRiskData["totalQuestion"]); // Total Business Risk Questions
          setOperationalRisk((businessRiskData["Operational Risk"]/businessRiskData["totalQuestion"])*100)
          setComplianceRisk((businessRiskData["Compliance Risk"]/businessRiskData["totalQuestion"])*100)
          setReputationalRisk((businessRiskData["Reputational Risk"]/businessRiskData["totalQuestion"])*100) // businessRiskData["Reputational Risk"])
          setFinancialRisk((businessRiskData["Financial Risk"]/businessRiskData["totalQuestion"])*100)
          setCybersecurityRisk((businessRiskData["Cybersecurity Risk"]/businessRiskData["totalQuestion"])*100) // businessRiskData["Cybersecurity Risk"])
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const getCompplianceMapping = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPLIANCE_MAPPING}?domain=${domainName}`
      )
        .then((res) => {
          const result = res.data.result;
          setTotalQuestions(result.totalQuestions || 0);
          setGdprTotalQuestions(result.gdprTotalQuestions || 0);
          setIso27001ControlTotalQuestions(result.iso27001ControlTotalQuestions || 0);
          setNistTotalQuestions(result.nistTotalQuestions || 0);
          setGdprFailedQuestions(result.setGdprFailedQuestions || 0);
          setIso27001ControlFailedQuestions(result.iso27001ControlFailedQuestions || 0)
          setNistFailedQuestions(result.nistFailedQuestions || 0);
          setGdprPassedQuestions(result.gdprPassedQuestions || 0);
          setIso27001ControlPassedQuestions(result.iso27001ControlPassedQuestions || 0);
          setNistPassedQuestions(result.nistPassedQuestions || 0);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const getResidualInheritanceAcceptableRisk = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_INHERENT_RESIDUAL_ACCEPTABLE_RISK}?domain=${domainName}`
      )
        .then((res) => {
          const acceptableRiskArr = res.data.acceptableRisk.split("-")
          setAcceptableRisk(acceptableRiskArr[1]);
          setResidualRisk(res.data.residualRisk);
          setInherentRisk(res.data.inherentRisk);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const getReletionshipTag = (score) => {
    let riskTag;
    if (score && score <= 25) {
      riskTag = "Low";
    } else if (score && score <= 50) {
      riskTag = "Medium";
    } else if (score && score <= 75) {
      riskTag = "High";
    } else if (score && score > 75) {
      riskTag = "Critical";
    }
    return riskTag;
  }
  return (
    <Container maxWidth={false} className="max-w-left">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <div className="inherent-risk-full-bacground">
            <div className="top-3-box-risk">
              <h3>Inherent Risk</h3>
              <h4>{Math.floor(inherentRisk) || 0}</h4>
              {inherentRisk ? (
                <span className={`tagClass ${getReletionshipTag(Math.floor(inherentRisk))}`}>
                  {getReletionshipTag(Math.floor(inherentRisk))}
                </span>
                ) : (
                "-"
                )}
            </div>
            <img src={ArrowRight} alt="arrow" />
            <div className="top-3-box-risk">
              <h3>Residual</h3>
              <h4>{Math.floor(residualRisk) || 0}</h4>
              {residualRisk ? (
                <span className={`tagClass ${getReletionshipTag(Math.floor(residualRisk))}`}>
                  {getReletionshipTag(Math.floor(residualRisk))}
                </span>
                ) : (
                "-"
                )}
            </div>
            <div className="top-3-box-risk">
              <h3>Acceptable Risk</h3>
              <h4>{acceptableRisk || 0}</h4>
               {acceptableRisk ? (
                <span className={`tagClass ${getReletionshipTag(Math.floor(acceptableRisk))}`}>
                  {getReletionshipTag(Math.floor(acceptableRisk))}
                </span>
                ) : (
                "-"
                )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="impact-analysis-section">
            <h2>Impact Analysis</h2>
            <img src={ImpactImges} alt="img" />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="bussiness-risk">
            <h2>Business Risk Ratio</h2>
            <Chart options={options} series={series} type="radar" height={500} />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="bussiness-risk">
            <h2>Compliance Mapping</h2>
            <div className="mapping-bussiness-left">
              <div className="flex-compliance-mapping">
                <div className="gdpr-box">
                  <h3>GDPR</h3>
                  <ReactApexChart options={options1} series={gdprseries} type="radialBar" height={350} />
                </div>
                <div className="gdpr-box">
                  <h3>ISO 27011</h3>
                  <ReactApexChart options={options1} series={ISOseries} type="radialBar" height={350} />
                </div>
                <div className="gdpr-box">
                  <h3>NIST</h3>
                  <ReactApexChart options={options1} series={nistSeries} type="radialBar" height={350} />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RiskProfile;
