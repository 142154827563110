// component
import { useSelector } from "react-redux";
import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const Navigation = () => {
  const navConfig = [
    {
      title: "Dashboard",
      path: "/dashboard/app",
      icon: icon("dashboard"),
    },
    {
      title: "Ecosystem",
      path: "/dashboard/vendors",
      icon: icon("ecosystem"),
    },
    {
      title: "Attack Surface",
      path: "/dashboard/attacks",
      icon: icon("attack-surface"),
    },
    {
      title: "Questionnaire",
      path: "/dashboard/questionnaire-library",
      icon: icon("questionnaire"),
    },
    {
      title: "Assessment",
      path: "/dashboard/assessment",
      icon: icon("assesment"),
    },
    {
      title: "chat",
      path: "/dashboard/clientChat",
      icon: icon("chat"),
    },
    // {
    //   title: "My Account",
    //   path: "/dashboard/my-account",
    //   icon: icon("peoples"),
    // },
  ];
  const navConfigAdmin = [
    {
      title: "Upload Json",
      path: "/admin/addfile",
      icon: icon("ic_analytics"),
    },
    {
      title: "All Ecosystems",
      path: "/admin/vendors",
      icon: icon("peoples"),
    },
    {
      title: "All Attack",
      path: "/admin/attacks",
      icon: icon("attack-icon"),
    },
    {
      title: "All Requests",
      path: "/admin/requests",
      icon: icon("ic_file"),
    },
    {
      title: "All Attack Requests",
      path: "/admin/all-attack-requests",
      icon: icon("attack-icon"),
    },
    {
      title: "Questionnaire Import",
      path: "/admin/questionnaire-import",
      icon: icon("questionnaire-line"),
    },
    {
      title: "Clients Management",
      path: "/admin/clients-management",
      icon: icon("client-management"),
    },
    {
      title: "Send Notifications",
      path: "/admin/notification",
      icon: icon("bell-icon"),
    },
  ];

  const navConfigVendor = [
    {
      title: "Assessments",
      path: "/vendor/vendorAssessment",
      icon: icon("ic_file"),
    },
    {
      title: "Remediation",
      path: "/vendor/vendorRemediation",
      icon: icon("Group1810"),
    },
    {
      title: "Compliance",
      path: "compliance",
      icon: icon("ic_analytics"),
    },
    {
      title: "Chat",
      path: "vendorChat",
      icon: icon("chat-icon"),
    },
  ];

  return {
    navConfig,
    navConfigVendor,
    navConfigAdmin,
  };
};

export default Navigation;
