import React, { useEffect, useState } from "react";
import CompanyInfo from "./companyInfo";
import Subscriptions from "./subscriptions";
import AddVendor from "./addVendor";
import { useSnackbar } from "notistack";
import PostRequest from "../../components/apiConnections/postRequest";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import GetRequest from "../../components/apiConnections/getRequest";
import "./../css/addClient.css";
import StepperComponent, { AddEditClientScreenTypes } from "./StepperComponent";
import Loader from "../../Loader";

const AddEditClient = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeComponent, setActiveComponent] = useState(
    AddEditClientScreenTypes.Details
  );
  const [showLoader, setShowLoader] = useState(false);
  const [client, setClient] = useState({
    clientId: id || null,
    logo: null,
    company: "",
    websites: [],
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    twoFAMethod: "email",
    numberOfVendors: 700,
    numberOfUsers: 20,
    numberOfWebsites: 0,
    isQuestionniareEnabled: 1,
    numberOfQuestionniare: 30,
    isAttackRequestEnabled: 1,
    numberOfAttackRequests: 10,
    isAssessmentEnabled: 1,
    numberOfAssessments: 5,
    selectedTab: 0,
    vendorDomainName: "",
    vendorDomain: [],
    filename: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const isUpdateMode = !!id;

  useEffect(() => {
    if (id) getClientData();
  }, []);

  const addClientDetails = (data) => {
    setClient({ ...client, ...data });
  };
  const changeActiveComponent = (componentName) => {
    if (
      isUpdateMode &&
      (componentName === AddEditClientScreenTypes.Subscriptions ||
        componentName === AddEditClientScreenTypes.AddVendors)
    ) {
      setActiveStep((prevStep) => prevStep + 1);
      setActiveComponent((prevComponent) => prevComponent + 1);
    } else {
      setActiveComponent(componentName);
      setActiveStep(componentName - 1);
    }
  };

  const getClientData = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_DATA}?clientId=${id}`
        )
          .then((resChart) => {
            let userDetail = resChart.data?.data;
            // setShowLoader(false);

            if (userDetail?._id) {
              let obj = {
                ...userDetail,
                logo: userDetail.profilePic,
                email: userDetail.emailId,
              };
              if (userDetail.clientDetail) {
                obj = { ...obj, ...userDetail.clientDetail };
                delete obj.clientDetail;
              }
              if (!obj.clientId) obj.clientId = obj.clientIdString
              setClient({ ...obj, });
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            // setShowLoader(false);
          });
      } else {
        // setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      // setShowLoader(false);
    }
  };

  const submitData = async (data, vendorInsertionType) => {
    setShowLoader(true);
    console.log(data.vendorDetails)
    let payload = new FormData();
    if (id) payload.append("clientId", id);
    payload.append("firstName", data.firstName || client.firstName);
    payload.append("lastName", data.lastName || client.lastName);
    payload.append("company", data.company || client.company);
    payload.append("email", data.email || client.email);
    payload.append("mobile", data.mobile || client.mobile);
    payload.append("twoFAMethod", data.twoFAMethod || client.twoFAMethod);
    payload.append("logo", data.logo || client.logo);
    payload.append("websites", JSON.stringify(data.websites));
    payload.append("numberOfVendors", client.numberOfVendors || 0);
    payload.append("numberOfUsers", client.numberOfUsers || 0);
    payload.append("numberOfWebsites", client.numberOfWebsites || 0);
    payload.append("isQuestionniareEnabled", client.isQuestionniareEnabled ? 1 : 0);
    payload.append("numberOfQuestionniare", client.numberOfQuestionniare || 0);
    payload.append("isAttackRequestEnabled", client.isAttackRequestEnabled ? 1 : 0);
    payload.append("numberOfAttackRequests", client.numberOfAttackRequests || 0);
    payload.append("isAssessmentEnabled", client.isAssessmentEnabled ? 1 : 0);
    payload.append("numberOfAssessments", client.numberOfAssessments || 0);
    payload.append("vendorDomain", JSON.stringify(data.vendorDomain || []));
    console.log("formData add client", payload)

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_MODIFY_CLIENT}`,
          payload
        )
          .then((resChart) => {
            if (vendorInsertionType === "Bulk") {
              addBulkVendor(data.bulkVendorList, data.email || client.email)
            } else if (data.vendorDetails instanceof FormData) {
              data.vendorDetails?.append("clientEmail", data.email || client.email)
              data.vendorDetails?.append("vendorStatus", data.vendorStatus || "Onboarding")
              addClientVendor(data.vendorDetails)
            } else {
              enqueueSnackbar(resChart.data?.message, { variant: "success" });
              setShowLoader(false);
              setClient({});
              navigate("/admin/clients-management");
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };
  const addClientVendor = async (data) => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_CLIENT_VENDOR_BY_ADMIN}`,
          data
        )
          .then((resChart) => {
            enqueueSnackbar("client added successfully", { variant: "success" });
            setShowLoader(false);
            setClient({});
            navigate("/admin/clients-management");
          })
          .catch((errChart) => {
            enqueueSnackbar("client created but failed to add vendor", { variant: "error" });
            navigate("/admin/clients-management");
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar("client created but failed to add vendor", { variant: "error" });
      navigate("/admin/clients-management");
      setShowLoader(false);
    }
  }

  const addBulkVendor = async (list, email) => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_BULK_VENDOR_OF_CLIENT}`,
          {
            clientEmail: email,
            vendorList: list?.vendorDomain
          },
          {}
        )
          .then((resChart) => {
            console.log(resChart, "resChart")
            if (resChart.data.code === 200) {
              enqueueSnackbar("client added successfully", { variant: "success" });
              setShowLoader(false);
              setClient({});
              navigate("/admin/clients-management");
            } else {
              enqueueSnackbar("client created but failed to add vendor", { variant: "success" });
              navigate("/admin/clients-management");
            }
          })
          .catch((errChart) => {
            enqueueSnackbar("client created but failed to add vendor", { variant: "error" });
            navigate("/admin/clients-management");
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar("client created but failed to add vendor", { variant: "error" });
      navigate("/admin/clients-management");
      setShowLoader(false);
    }
  }
  useEffect(() => {
  }, [client.vendorDomain])
  return (
    <>
      <Loader show={showLoader} />
      {(() => {
        switch (activeComponent) {
          case AddEditClientScreenTypes.Details:
            return (
              <CompanyInfo
                props={{
                  client,
                  addClientDetails,
                  changeActiveComponent,
                  submitData,
                }}
                stepper={<StepperComponent activeStep={activeStep} />}
              />
            );
          case AddEditClientScreenTypes.Subscriptions:
            return (
              <Subscriptions
                props={{
                  client: client,
                  addClientDetails: addClientDetails,
                  changeActiveComponent: changeActiveComponent,
                }}
                stepper={<StepperComponent activeStep={activeStep} />}
              />
            );
          case AddEditClientScreenTypes.AddVendors:
            return (
              <AddVendor
                props={{
                  client: client,
                  addClientDetails: addClientDetails,
                  changeActiveComponent: changeActiveComponent,
                  submitData: submitData,
                }}
                stepper={<StepperComponent activeStep={activeStep} />}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default AddEditClient;
