import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
} from "@mui/material";
import ReactApexChart from 'react-apexcharts';
import { useSnackbar } from "notistack";
import GetRequest from "../../components/apiConnections/getRequest";

function ComplianceMappingPage({domainName,assessmentId}) {
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [gdprTotalQuestions, setGdprTotalQuestions] = useState(0);
  const [iso27001ControlTotalQuestions, setIso27001ControlTotalQuestions] = useState(0);
  const [nistTotalQuestions, setNistTotalQuestions] = useState(0);
  const [gdprFailedQuestions, setGdprFailedQuestions] = useState(0);
  const [iso27001ControlFailedQuestions, setIso27001ControlFailedQuestions] = useState(0);
  const [nistFailedQuestions, setNistFailedQuestions] = useState(0);
  const [gdprPassedQuestions, setGdprPassedQuestions] = useState(0);
  const [iso27001ControlPassedQuestions, setIso27001ControlPassedQuestions] = useState(0);
  const [nistPassedQuestions, setNistPassedQuestions] = useState(0);


  useEffect(() => {
    getCompplianceMapping();
  }, []);

  // Define options for the concentric radial bars
  const options1 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent', // Set chart background to transparent
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Average',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Failed', 'Total', 'Passed'],
    colors: ['#FF4560', '#FEB019', '#00E396'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#FF4560', '#FEB019', '#00E396'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  // Series data for each concentric radial bar
  const gdprseries = [gdprFailedQuestions, gdprTotalQuestions, gdprPassedQuestions];
  const nistSeries = [nistFailedQuestions, nistTotalQuestions, nistPassedQuestions]
  const ISOseries = [iso27001ControlFailedQuestions, iso27001ControlTotalQuestions, iso27001ControlPassedQuestions]

  const getCompplianceMapping = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPLIANCE_MAPPING}?domain=${domainName}&assessmentId=${assessmentId}`
      )
        .then((res) => {
          const result = res.data.result;
          console.log("mapping tab res:",res);
          setGdprTotalQuestions(result.gdprTotalQuestions || 0);
          setIso27001ControlTotalQuestions(result.iso27001ControlTotalQuestions || 0);
          setNistTotalQuestions(result.nistTotalQuestions || 0);
          setGdprFailedQuestions(result.setGdprFailedQuestions || 0);
          setIso27001ControlFailedQuestions(result.iso27001ControlFailedQuestions || 0)
          setNistFailedQuestions(result.nistFailedQuestions || 0);
          setGdprPassedQuestions(result.gdprPassedQuestions || 0);
          setIso27001ControlPassedQuestions(result.iso27001ControlPassedQuestions || 0);
          setNistPassedQuestions(result.nistPassedQuestions || 0);
          setLoader(false);
        })
        .catch((err) => {
          enqueueSnackbar("Bussiness risk ration not found", { variant: "error" });
          setLoader(false);
          return false;
        });
    } catch (error) {
      enqueueSnackbar("Error while fetch Bussiness risk ration", { variant: "error" });
      setLoader(false);
      return false;
    }
  };

  return (
    <Container maxWidth={false}>
      <Typography>Mapping</Typography>
            <div className="mapping-bussiness-left">
              <div className="flex-compliance-mapping">
                <div className="gdpr-box-mappingtab">
                  <h3>GDPR</h3>
                  <ReactApexChart options={options1} series={gdprseries} type="radialBar" height={350} />
                </div>
                <div className="gdpr-box-mappingtab">
                  <h3>ISO 27011</h3>
                  <ReactApexChart options={options1} series={ISOseries} type="radialBar" height={350} />
                </div>
                <div className="gdpr-box-mappingtab">
                  <h3>NIST</h3>
                  <ReactApexChart options={options1} series={nistSeries} type="radialBar" height={350} />
                </div>
              </div>
            </div>
    </Container>
  );
}

export default ComplianceMappingPage;
