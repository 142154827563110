import React, { useEffect, useState } from 'react'
import InfoIcon from "../../assets/info-icon.svg";
import LinkedDin from "../../assets/linkdin.png";
import SemiChart from "react-apexcharts";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Box } from "@mui/material";
import GaugeComponent from 'react-gauge-component';
import UpIcon from '../../assets/up-icon.svg';
import DNS from '../../assets/dns-image.svg';
import Chart from '../../components/chart';
import Carousel from 'react-material-ui-carousel';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DScore from '../../assets/d-score.svg';
import AGrade from '../../assets/A-Grade.svg';
import BGrade from '../../assets/B-Grade.svg';
import CGrade from '../../assets/C-Grade.svg';
import moment from 'moment';
import DownIcon from '../../assets/down-arrow.svg';
import CircularProgress from "./../CircularProgress.js";
import {
  AppWebsiteVisits,
} from "../../sections/@dashboard/app";
import { useSnackbar } from 'notistack';
import PostRequest from '../../components/apiConnections/postRequest.js';

function OverviewHome({ data, sumScore, totalCritical, totalHigh, totalLow, totalMedium, complianceCardData, rows, totalScore, overallOldScore, complianceLength, riskScore }) {
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [domainName, setDomainName] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [description, setDescription] = useState("");
  const [linkdin, setlinkdin] = useState("");
  const [IPaddress, setIPAddress] = useState("");
  const [size, setSize] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [industry, setIndustry] = useState("");
  const [vendorCurrentStatus, setVendorCurrentStatus] = useState("");
  const [contractDeadline, setContractDeadline] = useState("");
  const [overallRiskScore, setOverallRiskScore] = useState(0);
  const [reletionShipRisk, setReletionShipRisk] = useState(0);
  const [oldScore, setOldScore] = useState([]);
  const [chartDataLoader, setChartDataLoader] = useState(false);
  const [graphDates, setGraphDates] = useState([]);
  const [graphValues, setGraphValues] = useState([]);
  const [option, setOption] = useState(365);
  useEffect(() => {
    setLogo(
      data?.vendorData?.vendorInfo?.logo ||
      "/assets/images/avatars/user.png"
    );
    setDomainName(data?.vendorData?.vendorInfo.domain);
    setVendorName(data?.vendorDetail?.name);
    setDescription(data?.vendorDetail?.description);
    setlinkdin(data?.vendorDetail?.linkedIn);
    setIPAddress(data?.vendorData?.vendorInfo?.ip);
    setSize(data?.vendorDetail?.numberOfEmployees)
    setAddress(data?.vendorDetail?.address);
    setCountry(data?.vendorDetail?.country);
    setIndustry(data?.vendorDetail?.industries[0])
    setVendorCurrentStatus(data?.requestUrlDetails?.vendorCurrentStatus)
    const forrmatedDeadline = moment(data?.vendorDetail?.contractDeadline).format('DD/MM/YYYY');
    setContractDeadline(forrmatedDeadline)
    setOverallRiskScore(
      data?.vendorData?.vendorInfo?.score[0]?.Overall_score
    );
    setOldScore(
      data?.vendorData?.vendorInfo?.oldScore
    );
    setReletionShipRisk(data?.vendorDetail?.relationshipRisk);
  }, [data]);

  useEffect(() => {
    if (domainName) {
      getChartData();
    }
  }, [domainName]);

  const getReletionshipTag = (score) => {
    let riskTag;
    if (score && score <= 25) {
      riskTag = "Low";
    } else if (score && score <= 50) {
      riskTag = "Medium";
    } else if (score && score <= 75) {
      riskTag = "High";
    } else if (score && score > 75) {
      riskTag = "Critical";
    }
    return riskTag;
  }
  const chartSeries = [totalCritical || 0, totalHigh || 0, totalMedium || 0, totalLow || 0];
  const chartSeries2 = [totalCritical || 0, totalHigh || 0, totalMedium || 0, totalLow || 0];
  /*Close first chart*/
  const isChartSeriesNullDonut = chartSeries.every(value => value === 0);
  const isChartSeries2NullDonut = chartSeries2.every(value => value === 0);
  const getEmptyDonutOptions = (baseOptions) => ({
    ...baseOptions,
    colors: ['#292154', '#292154', '#292154', '#292154'], // Light color to show "empty" donut
    plotOptions: {
      pie: {
        donut: {
          ...baseOptions.plotOptions.pie.donut,
          labels: {
            show: true,
            total: {
              show: true,
              label: 'No Data',
              fontSize: '16px',
              color: '#fff', // Muted color for "No Data" text
              fontFamily: 'Mona-Sans, sans-serif',
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff', // Muted color for total value
              },
              formatter: () => '0', // Show "0" for total when no data
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    tooltip: {
      enabled: false, // Disable tooltip on empty donut
    }
  });
  const chartOptions2 = {
    chart: {
      type: 'donut',
      width: "250",
      height: '250',
      dropShadow: {
        enabled: true,
        color: '#000', // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25 // Opacity of the shadow
      }
    },
    labels: [`Critical ${totalCritical || 0}`, `High ${totalHigh || 0}`, `Medium ${totalMedium || 0}`, `Low ${totalLow || 0}`],
    colors: ['#CC2121', '#BA5705', '#BFB608', '#3C851F'],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      },
      breakpoint: 1200,
      options: {
        chart: {
          width: 300
        },

      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '78%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 30,  // Move the name (category) label down
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff',
              offsetY: -15, // Move the value label up
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#fff',
              fontFamily: 'Mona-Sans, sans-serif',
              formatter: () => totalCritical + totalMedium + totalHigh + totalLow,
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff',
              },
              offsetY: 50, // Move total label down
            },
          }
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: 'Mona-Sans, sans-serif',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'right',
      offsetY: 40,
      height: 150,
      markers: {
        width: 11,           // Width of the square marker
        height: 12,          // Height of the square marker
        shape: 'square',     // Set the shape to square
        radius: 0,           // No border-radius for square
        offsetX: -5,
      }
    }
  };
  // Donut chart options and series
  const donutoptions = {
    chart: {
      height: 100,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -140,
        endAngle: 140,
        hollow: {
          size: "65%",
        },
        track: {
          background: "#332961", // Track background color
          strokeWidth: "100%", // Track thickness
          margin: 0, // Space between bar and track
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "16px",
          },
          value: {
            show: true,
            fontSize: "16px",
            color: "#fff",
            offsetY: -10, // Center the value vertically
            formatter: function (val) {
              return val + "";
            },
          },
        },
        rounded: false, // Disable rounded corners

      },
    },
    colors: ["#533460"], // Single color for the segment
    stroke: {
      lineCap: "",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#E6171D"],
        inverseColors: false,
        stops: [0, 100],
      },
    },
    labels: [""],
  };
  const getScorPercent = (overallRiskScore, overallOldScore) => {
    if (overallOldScore) {
      if (overallOldScore === 0) {
        const score = overallRiskScore > 0 ? 100 : 0;
        return parseFloat(score.toFixed(2));
      }
      const score = ((overallRiskScore - overallOldScore) / overallOldScore) * 100;
      return parseFloat(score.toFixed(2));
    } else {
      return 0;
    }
  }
  const totalIssuesChange = async (event) => {
    setOption(event.target.value);
  };
  useEffect(() => {
    getChartData();
  }, [option]);

  const getChartData = async () => {
    setChartDataLoader(true);
    const payload = {
      selectedDays: option,
      domain: domainName
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_RISK_SCORE_GRAPH_DATA}`,
          payload
        )
          .then((resChart) => {
            let allRiskScore = [];
            let allDates = [];
            if (resChart?.data) {
              resChart?.data.map((datas) => {
                allDates.push(datas.date)
                allRiskScore.push(datas.averageScore)
              });
              setGraphDates(allDates);
              setGraphValues(allRiskScore);
              setChartDataLoader(false);
            } else {
              enqueueSnackbar("No data found for monthly chart.", {
                variant: "error",
              });
              setChartDataLoader(false);
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: "error" });
            setChartDataLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setChartDataLoader(false);
    }
  };


  return (
    <div className='top-first-tab'>
      <p className='general-icon'><img src={InfoIcon} alt='icon' /> General Info</p>
      <div className='about-part-section-info'>
        <h3 style={{ display: "flex", alignItems: "center" }}><span className="logo-image-vendor"><img className="attack-surface-list-logo" src={logo} alt='icon' /></span> {vendorName} </h3>
        <p>{description}</p>
        <div className='top-line-flex-first'>
          <div className='all-detail-information'>
            <p>{vendorName}</p>
            <div className='d-flex-inline'>
              <p>Website:</p>
              <p>{domainName}</p>
            </div>
            <div className='d-flex-inline'> <p>IP:</p>
              <p>{IPaddress}</p>
            </div>
            <div className='d-flex-inline'>
              <p>Social: </p>
              <p>
                <a href={linkdin} target="_blank" rel="noopener noreferrer">
                  <img src={LinkedDin} alt="LinkedIn Icon" />
                </a>
              </p>
            </div></div>

          <div className='all-detail-information'>
            <div className='d-flex-inline'>
              <p>Revenue:  </p>  <p>100k</p>  </div>
            <div className='d-flex-inline'><p>Size:</p> <p> {size}</p> </div>
            <div className='d-flex-inline'><p>Address:</p><p>{address} {country}</p></div>
            <div className='d-flex-inline'><p>Industry Type:</p><p>{industry}</p></div>
          </div>

          <div className='all-detail-information'>
            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}> Status:
              {vendorCurrentStatus !== "Due diligence" ? (
                <span className={`statusClass ${vendorCurrentStatus}`}>
                  {vendorCurrentStatus}</span>
              ) : <span className={`statusClass Due_diligence`}>{vendorCurrentStatus}</span>
              }
            </p>
          </div>

          <div className='all-detail-information'>
            <p>Deadline: <span className='deadline'>{contractDeadline}</span></p>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <div className='overall-risk-overview-page'>
            <div className='flex-graph-half-donut'>
              <div className='graph-risk'>
                <p className='mtop-0'>Overall Risk</p>
                <div className='graph-design-donut'>
                  <div className='gauge-semicircle gauge-container' style={{ width: '400px', height: '250px' }}>
                    <span className="number-up position-right-side-overall" style={{ fontSize: "medium", top: "59px"}}>
                      {/* <img src={UpIcon} alt="up icon" /> {getScorPercent(overallRiskScore, overallOldScore)}% */}
                      {Number(overallRiskScore) !== Number(overallOldScore) ? <span className={`number-up ${Number(overallRiskScore) < Number(overallOldScore) ? "number-down" : null}`}>
                        {(Number(overallRiskScore) && Number(overallOldScore)) ? <> <img src={
                          Number(overallRiskScore) < Number(overallOldScore) ? DownIcon : UpIcon} alt="icon" />{
                            ((Math.abs(Number(overallRiskScore) - Number(overallOldScore)) * 100) / Number(overallOldScore))?.toFixed(2)}%</> : "0%"}
                      </span> :"0%"}
                    </span>
                    <GaugeComponent
                      type="semicircle" // Using a semicircular gauge
                      arc={{
                        colorArray: ['#FF0000', '#FF4D00', '#FD9B3D', '#45D7A2'],
                        padding: 0.06, // Adjust padding for smoother arc transitions
                        subArcs: [
                          { limit: 25 },  // 25% of the total gauge
                          { limit: 50 },  // Next 25%
                          { limit: 75 },  // Next 25%
                          { limit: 100 }, // Last 25%
                        ],
                        arcWidth: 0.8, // Adjust arc thickness
                      }}

                      pointer={{ type: 'blob', animationDelay: 0, color: "#ffffff" }}
                      value={overallRiskScore !== null ? overallRiskScore : 0}
                      label={{
                        display: true,
                        text: `${overallRiskScore !== null ? overallRiskScore : 0}/100`,
                        style: {
                          fontSize: '25px',
                          fontWeight: 'bold',
                          fill: '#ffffff',
                          fontFamily: 'Mona-Sans',
                        },
                      }}
                    />
                    <p className='last-scan' style={{ textAlign: 'center', color: '#fff', marginTop: '0px' }}>
                    Last scanned 26 Sep
                    </p>
                  </div>
                </div>
              </div>

              <div className='inside-scan-div'>
                <p className='outside-side-scan mb-12-side'>Inside Out - Scan</p>
                <div className="fullcover-width">
                  <CircularProgress percent={sumScore} />
                </div>
                <div className="fullcover-width">

                  <CircularProgress percent={riskScore} />
                </div>
                <p className='outside-side-scan'>Outside in - Scan</p>
              </div>

              <div className='Relationship Risk'>
                <p>Relationship</p>
                {/* {reletionShipRisk ? (<img src={getReletionshipImage(reletionShipRisk)} alt="icon" />)
                  : "-"} */}
                {reletionShipRisk ? (
                  <span className={`tagClass ${getReletionshipTag(reletionShipRisk)}`}>
                    {getReletionshipTag(reletionShipRisk)}
                  </span>
                ) : (
                  "-"
                )}
                <div className='ranking-total'>
                  <h3>{totalScore}</h3>
                  <p>Total Findings</p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className='flex-start-activity'>
          <div className='daily-activity'>
            <h3>Daily Activity</h3>
            <div className='bg-backgrounds'>
              <div className='heading-long-short'>
                <div>
                  <h4>DMARC Record Found</h4>
                  <p>Deleted 2 Item from Group Prime</p></div>
                <div>
                  <img src={UpIcon} alt="up icon" /><span> 20%</span></div>
              </div>
              <div className='heading-long-short'>
                <div>
                  <h4>DMARC Record Found</h4>
                  <p>Deleted 2 Item from Group Prime</p></div>
                <div>
                  <img src={UpIcon} alt="up icon" /><span> 20%</span></div>
              </div>
              <div className='heading-long-short'>
                <div>
                  <h4>DMARC Record Found</h4>
                  <p>Deleted 2 Item from Group Prime</p></div>
                <div>
                  <img src={UpIcon} alt="up icon" /><span> 20%</span></div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <div className='same-base-bg'>
            <div className="top-heading-dashboard">
              <h2 style={{ margin: "13px 0px 0px" }}>Issue Distribution</h2>
              <div className="legend-text-issue-legend top-space-middle-chart">
                {(totalCritical + totalMedium + totalHigh + totalLow) > 0 ? (
                  <Chart
                    options={chartOptions2}
                    series={chartSeries2}
                    type="donut"
                  />
                ) : (
                  <Chart
                    options={getEmptyDonutOptions(chartOptions2)}
                    series={[1]}
                    type="donut"
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <div className='same-base-bg'>
            <h2>Risk Score</h2>
            <div className="in-chart-bar charts-bar-label-design bg-newvendor-detail">
              <AppWebsiteVisits
                chartLabels={graphDates}
                loading={chartDataLoader}
                chartData={[
                  {
                    name: "Risk Score",
                    type: "area",
                    fill: "gradient",
                    data: graphValues,
                  }
                ]}
                totalIssuesChange={totalIssuesChange}
                option={option}
              />
            </div>
          </div>
        </Grid>

        {/* compliances card */}
        <Grid item xs={12} md={6} lg={6}>
          <div className='same-base-bg carousel-box-inline'>
            <h2>Compliance</h2>
            {complianceCardData?.length ? (
              <Box>
                <Carousel
                  navButtonsAlwaysVisible={true}
                  autoPlay={false}
                  indicators={false}
                >
                  {complianceCardData?.map((card, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {card.length
                          ? card?.map((item, j) => {
                            return (
                              <Box
                                sx={{ marginX: "10px" }}
                                key={j}
                              >
                                <h3>{item?.name}</h3>
                                <img
                                  src={`${process.env
                                    .REACT_APP_BACKEND_BASE_URL
                                    }${item.complianceType ==
                                      "Others"
                                      ? process.env
                                        .REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO
                                      : process.env
                                        .REACT_APP_STATIC_PATH_ASSETS
                                    }/${item.logo}`}
                                  className="iso-image"
                                  alt="logo"
                                />
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_DOCUMENT}/${item.document}`}
                                >
                                  <p className="link-circle">
                                    <KeyboardArrowRightIcon />
                                  </p>
                                </a>
                              </Box>
                            );
                          })
                          : null}
                      </Box>
                    );
                  })}
                </Carousel>
              </Box>
            ) : (
              <div className="no-card-data">
                <p>No Compliance Data Found</p>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <div className='same-base-bg' style={{ height: "59% !important" }}>
            <h2>Outside In Score</h2>
            {rows ?
              (<TableContainer className='tablesame-outsidescore'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Home</TableCell>
                      <TableCell>Grade</TableCell>
                      <TableCell>Risk Score</TableCell>
                      <TableCell>Issues</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow>
                        <TableCell sx={{
                          display: "flex",
                          alignItems: "center", gap: "10px", paddingBottom: "6.2%"
                        }}><img src={DNS} />{row.category}</TableCell>
                        <TableCell>
                          {row.grade ? <img src={
                            (row.grade === 'A' ? AGrade : row.grade === 'B' ? BGrade : row.grade === 'C' ? CGrade : DScore)
                          } alt='icon' /> : "-"}
                        </TableCell>
                        <TableCell className="relative radialBar-new">
                          {row.score ? <div className='radialBar mr-side0'>
                            <SemiChart id="radialBar-new-overviewpage" className="" options={{
                              ...donutoptions,
                              fill: {
                                ...donutoptions.fill,
                                gradient: {
                                  ...donutoptions.fill.gradient,
                                  gradientToColors: [row?.score <= 25 ? "#E6171D" : row?.score <= 50 ? "#B35405" : row?.score <= 75 ? "#B7AF08" : "#4FE71CB2"]
                                }
                              }
                            }} series={[row?.score]} type="radialBar" height={93} />
                            {Number(row?.score) !== Number(row?.oldScore) && <span className={`number-up ${Number(row?.score) < Number(row?.oldScore) ? "number-down" : null}`}>
                              {(Number(row?.score) && Number(row?.oldScore)) ? <> <img src={
                                Number(row?.score) < Number(row?.oldScore) ? DownIcon : UpIcon} alt="icon" />{
                                  ((Math.abs(Number(row?.score) - Number(row?.oldScore)) * 100) / Number(row?.oldScore))?.toFixed(2) || 0}% </> : "0%"}
                            </span>}
                          </div> : "-"}

                        </TableCell>
                        <TableCell>
                          {row.issue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>) :
              (<Box sx={{ display: "flex", justifyContent: "center", minHeight: "190px", alignItems: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
              )}

          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default OverviewHome