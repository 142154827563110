import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Container,
  styled,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Modal,
  Typography,
  InputLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GetRequest from "../../components/apiConnections/getRequest.js";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest.js";
import SendArrow from '../../assets/top-arrow-btn.svg';
import VendorDetailAnswer from "./VendorDetailAnswer.js";
import ComplianceBarChart from "./ComplianceBarChart.js";



function Compliance({
  complainceData
}) {
  console.log(complainceData)
  const data = useParams();
  const navigate = useNavigate();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [domainName, setDomainName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sendAssessmentModal, setSendAssessmentModal] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [complianceCardData, setComplianceCardData] = useState([]);
  const [complianceRows, setComplianceRows] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = useState([
    { field: "uid", headerName: "#", editable: false, maxWidth: 60,disableColumnMenu: true, },
    { field: "set", headerName: "Set", editable: false, minWidth: 130, headerAlign: 'left', align: 'left', flex: 1 ,disableColumnMenu: true, },    
    { field: "firstName", headerName: "Name", editable: false, headerAlign: 'left', align: 'left', flex: 1 ,disableColumnMenu: true,},   
    {
      field: "score",
      headerName: "Score",
      editable: false,
      sortable: true,
      minWidth: 150,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        
        let {totalAnswereGiven, totalAnswerePass, totalQuestionsInAssessment} = params.row;
        if(!totalQuestionsInAssessment || totalQuestionsInAssessment===0 ) return <>-</>
        const score = (totalAnswerePass/totalQuestionsInAssessment) * 100 
        return <ComplianceBarChart fillPercentage={parseInt(score)}/>
        // return <> { Math.trunc(score)} /100 </> 
      },
    },
    {
      field: "completion",
      headerName: "Completion",
      editable: false,
      sortable: true,
      minWidth: 70,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        let {totalAnswereGiven, totalAnswerePass, totalQuestionsInAssessment} = params.row;
        if(!totalQuestionsInAssessment || totalQuestionsInAssessment===0 ) return <>-</>
        const score = (totalAnswereGiven/totalQuestionsInAssessment) * 100 
        return  <>{ Math.trunc(score) }% </>
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      editable: false,
      sortable: true,
      minWidth: 100,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "deadline",
      headerName: "Deadline",
      editable: false,
      sortable: true,
      minWidth: 100,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'assessmentStatus',
      headerName: 'Status',
      editable: false,
      minWidth: 150,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        let status = params.value;
        return (status !== "Extended Deadline" ? 
          <span className={`statusClass ${status}`}>{status}</span> 
          : 
          <span className={`statusClass Extended_Deadline`}>{status}</span>
        );
      },
    },
    
  ]);

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
 
 
  useEffect(() => {
  
  }, []);

  // Menu component for the three-dot menu
  const handleRowClick = (params) => {
    console.log(params)
    navigate(`/dashboard/vendor-answer/${params._id}`, { state: { data: params,email: params?.email, prevUrl: '/dashboard/assessment' } });
  };

  return (
    <>
      {/* <Loader show={loading} /> */}
      <Card className="full-coverdesign margin-questionnaire">
     
      <Grid container spacing={3} sx={{ mb: 0 }} className="ipad-space">
          <Grid item xs={12} md={12}>
            <div className="cover-table">
              {/* <QuestionnaireTable/> */}
              {/*New Table */}
              <div style={{ display: 'flex', alignItems: 'center', }}>
                <FormControl className='select-entries topmove-form' variant="outlined" size="small">
                  <Select
                    value={paginationModel.pageSize}
                    onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <span className='entries-per topmove-entries'>entries per page</span>
                <Button
                  variant="contained"
                  className="send-assessment"
                  onClick={() => navigate(`../vendor-information/${vendorUrl}`, { state: `vendorDetails/${vendorUrl}` })}
                >
                  <img src={SendArrow} className="send-arrow-icon" /> Send Assessment
                </Button>
              </div>

              <Box
                sx={{
                  width: '100%',
                  '& .MuiDataGrid-columnHeaders': {
                    minWidth: '100%',
                    overflowX: 'hidden',
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    minWidth: '100%',
                    overflowX: 'auto',
                  },
                }}
              >
                <DataGrid
                loading={showLoader}
                  rows={complainceData} // Ensure you're using the filtered rows
                  columns={columns}
                  pageSize={pageSize}
                  rowHeight={70}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  paginationMode="client"
                  autoHeight
                  className='datagrid-table'
                  sx={{
                    '& .MuiDataGrid-columnHeaders': {
                      minWidth: '100%', // Column headers take up full width
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minWidth: '100%', // Rows take up full width
                    },
                  }}
                  onRowClick={(params) => handleRowClick(params.row)}
                />
              </Box>
            </div>
          </Grid>
        </Grid>
          
        
      </Card>
      {openSidebar &&
        <VendorDetailAnswer
          handleClose={() => setOpenSidebar(false)}
          open={openSidebar}
        />
      }
    
    </>
  );
}

export default Compliance;
